import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import { UI_CONFIGS } from "@/utils/const";
import { generateNonce } from "@/utils/util";

export default {
  computed: {
    ...mapGetters({
      loggedIn: "session/isReady",
      autoloadConfigValue: "config/getAutoloadConfigValue",
    }),
    nameOnReg() {
      return this.autoloadConfigValue(UI_CONFIGS.NAME_ON_REG) === "true";
    },
  },
  async mounted() {
    if (this.loggedIn) {
      await this.$router.push("/user/profile");
    }
  },
  async created() {
    if (!!this.$route.meta.mortgage) {
      await this.fetchMortgageRegConfig();
      this.regConfigs =
        this.$store.getters["acceptanceRequirements/mortgageRegConfig"];
    } else {
      await this.fetchRegConfig();
      this.regConfigs = this.$store.getters["acceptanceRequirements/regConfig"];
    }
    this.regConfigs.forEach((rc) => {
      if (rc.promptDocument === "null") {
        rc.promptDocument = null;
      }
    });
    this.regConfigsLoaded = true;
  },
  data() {
    return {
      nonce: generateNonce(),
      lastName: "",
      firstName: "",
      phone: "",
      password: "",
      passwordValid: false,
      regConfigs: [],
      loading: false,
      regConfigsLoaded: false,
      formValidated: false,
    };
  },
  methods: {
    ...mapActions({
      fetchRegConfig: "acceptanceRequirements/fetchRegConfig",
      fetchMortgageRegConfig: "acceptanceRequirements/fetchMortgageRegConfig",
    }),
    async validateRegistration() {
      let statementAcceptances = this.$refs.statementAcceptances;

      this.formValidated = true;
      let valid = await this.$refs.regObserver.validate();
      let statementsValid = await statementAcceptances.validate();
      return valid && this.passwordValid && statementsValid;
    },
    getAcceptedStatements() {
      let statements = [];

      let statementAcceptances = this.$refs.statementAcceptances;
      for (let rc in this.regConfigs) {
        if (
          statementAcceptances.statementValueBucket[
            this.regConfigs[rc].promptId
          ]
        ) {
          statements.push({
            statementId: this.regConfigs[rc].promptId,
            statementIdentifier: this.regConfigs[rc].promptIdentifier,
            documentId: this.regConfigs[rc].promptDocument,
            link: this.regConfigs[rc].link,
            statementLabel: this.regConfigs[rc].promptLabel,
            isRequired: this.regConfigs[rc].isRequired,
          });
        }
      }
      return statements;
    },
    createRequest() {
      let statements = this.getAcceptedStatements();

      let request = {
        phone: this.phone,
        password: this.password,
        nonce: this.nonce,
        acceptedStatements: statements,
      };

      if (this.nameOnReg) {
        request.firstName = this.firstName.trim().replace(/\s+/g, " ");
        request.lastName = this.lastName.trim().replace(/\s+/g, " ");
      }

      return request;
    },
    handleRegistrationException(ex, messageToken) {
      this.loading = false;
      console.error(ex);
      if (ex.response.status === 409) {
        this.$buefy.toast.open({
          message: i18n.t(messageToken),
          type: "is-danger",
        });
      }
    },
  },
};
