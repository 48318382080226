<template>
  <div>
    <form class="af-card mb-4" @submit.prevent="doSubmit">
      <span class="has-text-grey-icon reg-title">{{
        $t("af:registration.title")
      }}</span>
      <p class="is-size-3 has-text-weight-bold reg-subtitle">
        {{ $t("af:registration.subtitle") }}
      </p>

      <validation-observer ref="regObserver">
        <ValidationProvider
          v-if="nameOnReg"
          rules="required"
          v-slot="{ errors, validated, valid }"
        >
          <b-field
            :label="$t('af:registration.input.last_name.label')"
            label-position="on-border"
            class="mb-5"
            :type="{ 'is-danger': errors[0], 'is-success': validated && valid }"
            :message="errors"
          >
            <b-input
              id="RegistrationInputLastName"
              v-model="lastName"
            ></b-input>
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          v-if="nameOnReg"
          rules="required"
          v-slot="{ errors, validated, valid }"
        >
          <b-field
            :label="$t('af:registration.input.first_name.label')"
            label-position="on-border"
            class="mb-5"
            :type="{ 'is-danger': errors[0], 'is-success': validated && valid }"
            :message="errors"
          >
            <b-input
              id="RegistrationInputFirstName"
              v-model="firstName"
            ></b-input>
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          v-if="mode === 'email'"
          rules="required|email"
          v-slot="{ errors, validated, valid }"
          mode="eager"
        >
          <b-field
            :label="$t('af:registration.input.email.label')"
            label-position="on-border"
            class="mb-5"
            :type="{ 'is-danger': errors[0], 'is-success': validated && valid }"
            :message="errors"
          >
            <b-input id="RegistrationInputEmail" v-model="email"></b-input>
          </b-field>
        </ValidationProvider>

        <p class="nonce-info has-text-grey-icon">
          {{ $t("af:registration.phone.subtitle") }}
        </p>

        <phone-input
          id="RegistrationInputPhone"
          :label="$t('af:registration.input.phone.label')"
          v-model="phone"
        />

        <b-field
          :label="$t('af:registration.input.password.label')"
          label-position="on-border"
          :type="{
            'is-danger': formValidated && !passwordValid,
            'is-success': formValidated && passwordValid,
          }"
          :message="
            formValidated && password.length === 0
              ? $t('af:form.validation.message.required')
              : null
          "
        >
          <PasswordCheckerInput
            id="RegistrationInputPassword"
            v-model="password"
            @validated="passwordValid = $event"
          />
        </b-field>

        <template v-if="regConfigsLoaded">
          <statements-acceptance
            :statements="regConfigs"
            layout-type="REG"
            read-required-validation-message="af:registration.message.validation.statement_read_required"
            ref="statementAcceptances"
            :is-public="true"
          />
        </template>
      </validation-observer>

      <b-field>
        <b-button
          id="RegistrationButtonSubmit"
          type="is-blue"
          native-type="submit"
          :loading="loading"
          expanded
        >
          {{ $t("af:registration.button.register") }}
        </b-button>
      </b-field>
      <p class="has-text-centered">
        <span>{{ $t("af:welcome.registration.footer.title") }}</span>
        <a @click="navigateToLogin" id="WelcomeButtonLogin" class="ml-2">
          {{ $t("af:welcome.registration.footer.button.to_login") }}
        </a>
      </p>
    </form>

    <b-modal v-model="showInvalidExternalIdModal" :can-cancel="false">
      <InvalidExternalIdModal />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import registrationMixin from "../mixins/registrationMixin";
import { APPLICATION_MODE, LOGIN_STATUS } from "@/utils/const";
import { getActiveLandingParams } from "@/utils/landingParamUtil";
import { sha256 } from "@/utils/util";
import InvalidExternalIdModal from "@/components/InvalidExternalIdModal.vue";
export default {
  name: "Registration",
  title: "af:page.registration.title",
  mixins: [registrationMixin],
  components: {
    InvalidExternalIdModal,
    PasswordCheckerInput: () => import("@/components/PasswordCheckerInput.vue"),
    StatementsAcceptance: () => import("@/components/StatementsAcceptance.vue"),
    PhoneInput: () => import("@/components/PhoneInput.vue"),
  },
  data() {
    return {
      mode: "", // email | phone
      email: "",
      mortgage: false,
      showInvalidExternalIdModal: false,
    };
  },
  computed: {
    ...mapGetters({
      offerId: "defaultOffer/getOfferIdentifier",
    }),
    externalId() {
      let landingParams = getActiveLandingParams();
      return landingParams ? JSON.parse(landingParams).externalId : null;
    },
    mortgageExternalId() {
      let landingParams = getActiveLandingParams();
      return landingParams
        ? JSON.parse(landingParams).mortgageExternalId
        : null;
    },
  },
  async mounted() {
    window.setTimeout(() => {
      if (this.loggedIn) {
        this.$router.push("/user/profile");
      }
    }, 1500);

    if (!this.mortgage && this.externalId != null) {
      const isExternalIdValid = await this.$store.dispatch(
        "session/isExternalIdValid",
        this.externalId
      );
      if (!isExternalIdValid) {
        this.showInvalidExternalIdModal = true;
      }
    }
  },
  async created() {
    this.mode = this.$route.meta.mode;
    this.mortgage = !!this.$route.meta.mortgage;
  },
  methods: {
    async doSubmit() {
      let valid = await this.validateRegistration();
      if (!valid) {
        return;
      }

      try {
        this.loading = true;

        let request = this.createRequest();
        request.email = this.email;
        request.initialOfferIdentifier = this.offerId;
        request.applicationMode = this.mortgage
          ? APPLICATION_MODE.MORTGAGE
          : APPLICATION_MODE.LOAN;

        if (this.mortgage) {
          request.mortgageExternalPrelimId = this.mortgageExternalId;
        } else {
          request.creditExternalPrelimId = this.externalId;
        }
        request.landingParams = getActiveLandingParams();

        let question = await this.$store.dispatch("session/register", request);

        await this.track();

        await this.$store.dispatch("session/saveSecondFactor", {
          id: question.id,
          nonce: this.nonce,
          status: LOGIN_STATUS.ENUM.REGISTRATION_INCOMPLETE,
          userId: question.userId,
        });
        await this.$router.push(
          this.mortgage
            ? "/mortgage/registration/second-factor"
            : "/registration/second-factor"
        );
      } catch (ex) {
        this.handleRegistrationException(
          ex,
          "af:registration.error.already_exists"
        );
      }
    },
    navigateToLogin() {
      if (this.$route.meta.mortgage) {
        this.$router.push({
          name: "MortgageWelcomeLogin",
        });
      } else {
        this.$router.push({
          name: "WelcomeLogin",
        });
      }
    },
    async track() {
      const data = {
        sha256_email_address: await sha256(this.email),
        sha256_phone_number: await sha256(this.phone),
        address: {
          "address.sha256_first_name": this.firstName,
          "address.sha256_last_name": this.lastName,
        },
      };
      this.$gtag.query("set", { user_data: data });
      this.$gtag.event("registrationFormSubmitted", { leadsUserData: data });
    },
  },
};
</script>

<style scoped lang="scss">
.af-card {
  background: white;
  border-radius: 16px;
  padding: 20px;
}

.is-link {
  color: #1072fc;
  cursor: pointer;
}

.nonce-info {
  margin-top: -1rem;
  margin-bottom: 2rem !important;
  max-width: 75%;
}

@media only screen and (min-width: 769px) {
  .af-card {
    padding: 32px 36px;
  }

  .mbh {
    .reg-title,
    .reg-subtitle {
      display: block;
      text-align: center;
    }

    .nonce-info {
      text-align: center;
      max-width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem !important;
      font-size: 14px;
    }
  }
}

.mbh {
  .is-size-3 {
    font-size: 32px !important;
  }

  .af-card {
    border-radius: 32px;
  }
}
</style>
