var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{staticClass:"af-card mb-4",on:{"submit":function($event){$event.preventDefault();return _vm.doSubmit.apply(null, arguments)}}},[_c('span',{staticClass:"has-text-grey-icon reg-title"},[_vm._v(_vm._s(_vm.$t("af:registration.title")))]),_c('p',{staticClass:"is-size-3 has-text-weight-bold reg-subtitle"},[_vm._v(" "+_vm._s(_vm.$t("af:registration.subtitle"))+" ")]),_c('validation-observer',{ref:"regObserver"},[(_vm.nameOnReg)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('b-field',{staticClass:"mb-5",attrs:{"label":_vm.$t('af:registration.input.last_name.label'),"label-position":"on-border","type":{ 'is-danger': errors[0], 'is-success': validated && valid },"message":errors}},[_c('b-input',{attrs:{"id":"RegistrationInputLastName"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1)]}}],null,false,577240233)}):_vm._e(),(_vm.nameOnReg)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('b-field',{staticClass:"mb-5",attrs:{"label":_vm.$t('af:registration.input.first_name.label'),"label-position":"on-border","type":{ 'is-danger': errors[0], 'is-success': validated && valid },"message":errors}},[_c('b-input',{attrs:{"id":"RegistrationInputFirstName"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1)]}}],null,false,1736086553)}):_vm._e(),(_vm.mode === 'email')?_c('ValidationProvider',{attrs:{"rules":"required|email","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('b-field',{staticClass:"mb-5",attrs:{"label":_vm.$t('af:registration.input.email.label'),"label-position":"on-border","type":{ 'is-danger': errors[0], 'is-success': validated && valid },"message":errors}},[_c('b-input',{attrs:{"id":"RegistrationInputEmail"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,false,3158089655)}):_vm._e(),_c('p',{staticClass:"nonce-info has-text-grey-icon"},[_vm._v(" "+_vm._s(_vm.$t("af:registration.phone.subtitle"))+" ")]),_c('phone-input',{attrs:{"id":"RegistrationInputPhone","label":_vm.$t('af:registration.input.phone.label')},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('b-field',{attrs:{"label":_vm.$t('af:registration.input.password.label'),"label-position":"on-border","type":{
          'is-danger': _vm.formValidated && !_vm.passwordValid,
          'is-success': _vm.formValidated && _vm.passwordValid,
        },"message":_vm.formValidated && _vm.password.length === 0
            ? _vm.$t('af:form.validation.message.required')
            : null}},[_c('PasswordCheckerInput',{attrs:{"id":"RegistrationInputPassword"},on:{"validated":function($event){_vm.passwordValid = $event}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),(_vm.regConfigsLoaded)?[_c('statements-acceptance',{ref:"statementAcceptances",attrs:{"statements":_vm.regConfigs,"layout-type":"REG","read-required-validation-message":"af:registration.message.validation.statement_read_required","is-public":true}})]:_vm._e()],2),_c('b-field',[_c('b-button',{attrs:{"id":"RegistrationButtonSubmit","type":"is-blue","native-type":"submit","loading":_vm.loading,"expanded":""}},[_vm._v(" "+_vm._s(_vm.$t("af:registration.button.register"))+" ")])],1),_c('p',{staticClass:"has-text-centered"},[_c('span',[_vm._v(_vm._s(_vm.$t("af:welcome.registration.footer.title")))]),_c('a',{staticClass:"ml-2",attrs:{"id":"WelcomeButtonLogin"},on:{"click":_vm.navigateToLogin}},[_vm._v(" "+_vm._s(_vm.$t("af:welcome.registration.footer.button.to_login"))+" ")])])],1),_c('b-modal',{attrs:{"can-cancel":false},model:{value:(_vm.showInvalidExternalIdModal),callback:function ($$v) {_vm.showInvalidExternalIdModal=$$v},expression:"showInvalidExternalIdModal"}},[_c('InvalidExternalIdModal')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }